import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";

// const TestimonialBubble = styled(Box)(({ theme }) => ({
//   background: "linear-gradient(135deg, #1a5f7a 0%, #2E8BC0 100%)",
//   borderRadius: "15px",
//   color: "white",
//   padding: theme.spacing(2),
//   width: "300px",
//   position: "relative",
//   [theme.breakpoints.down('md')]: {
//     width: "250px",
//   },
//   "&::after": {
//     content: '""',
//     position: "absolute",
//     bottom: "-15px",
//     left: "20px",
//     borderWidth: "15px 15px 0",
//     borderStyle: "solid",
//     borderColor: "#2E8BC0 transparent transparent",
//   },
// }));

// const WhiteBox = styled(Box)(({ theme }) => ({
//   background: "white",
//   padding: theme.spacing(2),
//   borderRadius: "15px",
//   width: "300px",
//   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//   display: "flex",
//   flexDirection: "column",
//   fontFamily: 'Syne, sans-serif',
//   [theme.breakpoints.down('md')]: {
//     width: "250px",
//     padding: theme.spacing(1.5),
//   },
// }));

export default function TestimonialsLayout() {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    // <Box
    //   sx={{
    //     bgcolor: "#FF5733",
    //     p: { xs: 2, sm: 3, md: 4 },
    //     minHeight: '1200px',
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     flexDirection: "column",
    //   }}
    // >
    //   <Box
    //     sx={{
    //       height: { xs: '400px', sm: '450px', md: '500px' },
    //       width: { xs: '300px', sm: '600px', md: '1000px' },
    //       backgroundColor: "whitesmoke",
    //       position: "relative",
    //       borderRadius: { xs: '25px', md: '50px' },
    //       marginTop: { xs: '150px', md: '200px' },
    //     }}
    //   >
    //     {/* Top left white box */}
    //     <WhiteBox
    //       sx={{
    //         position: "absolute",
    //         width: { xs: "200px", md: "300px" },
    //         height: { xs: "100px", md: "120px" },
    //         top: { xs: '-50px', md: "-100px" },
    //         left: { xs: '-20px', md: "-150px" },
    //         fontSize: { xs: '1rem', md: "2rem" },
    //       }}
    //     >
    //       <Typography sx={{ fontSize: { xs: '0.9rem', md: '1.2rem'  } }}>
    //         Crafting stunning, functional websites.
    //       </Typography>
    //       <Typography sx={{ mt: 1, fontStyle: "italic", fontSize: { xs: '0.8rem', md: '1rem' } }}>
    //         -CFO, Industry
    //       </Typography>
    //     </WhiteBox>

    //     {/* Top right image */}
    //     <Box
    //       sx={{
    //         position: "absolute",
    //         top: { xs: '-60px', md: "-120px" },
    //         right: { xs: '-20px', md: "-150px" },
    //         img: {
    //           width: { xs: '100px', md: 'auto' },
    //           height: 'auto',
    //         }
    //       }}
    //     >
    //       <img src="image/Group 34.png" alt="Top testimonial" />
    //     </Box>

    //     {/* Middle section */}
    //     <Box
    //       sx={{
    //         position: "absolute",
    //         top: "40%",
    //         transform: "translateY(-50%)",
    //         left: { xs: '-40px', md: "-90px" },
    //         display: "flex",
    //         alignItems: "center",
    //         gap: { xs: 2, md: 4 },
    //       }}
    //     >
    //       <img 
    //         src="image/Group 35.png" 
    //         alt="Middle testimonial" 
    //         style={{
    //           width: isMobile ? '80px' : 'auto',
    //           height: 'auto',
    //         }}
    //       />
    //       <WhiteBox sx={{
    //         position: "absolute",
    //         width: { xs: "200px", md: "300px" },
    //         bottom: { xs: '100px', md: '200px' },
    //         left: { xs: '300px', md: '500px' },
    //       }}>
    //         <Typography sx={{ fontSize: { xs: '0.9rem', md: '1.2rem' } }}>
    //           Crafting stunning, functional websites.
    //         </Typography>
    //         <Typography sx={{ mt: 0, fontStyle: "italic", fontSize: { xs: '0.8rem', md: '1rem' } }}>
    //           -CFO, Industry
    //         </Typography>
    //       </WhiteBox>
    //     </Box>

    //     {/* Bottom right white box */}
    //     <WhiteBox
    //       sx={{
    //         position: "absolute",
    //         width: { xs: "200px", md: "300px" },
    //         bottom: { xs: '100px', md: '200px' },
    //         left: { xs: "400px", md: "800px" },
    //         fontSize: { xs: '20px', md: '50px' },
    //       }}
    //     >
    //       <Typography sx={{ fontSize: { xs: '0.9rem', md: '1.2rem' } }}>
    //         Crafting stunning, functional websites.
    //       </Typography>
    //       <Typography sx={{ mt: 1, fontStyle: "italic", fontSize: { xs: '0.8rem', md: '1rem' } }}>
    //         -CFO, Industry
    //       </Typography>
    //     </WhiteBox>

    //     {/* Bottom right image */}
    //     <Box
    //       sx={{
    //         position: "absolute",
    //         bottom: { xs: '-100px', md: "-200px" },
    //         right: { xs: '15px', md: "30px" },
    //         img: {
    //           width: { xs: '100px', md: 'auto' },
    //           height: 'auto',
    //         }
    //       }}
    //     >
    //       <img src="image/Group 36.png" alt="Bottom testimonial" />
    //     </Box>

    //     {/* Bottom left white box */}
    //     <WhiteBox
    //       sx={{
    //         position: "absolute",
    //         width: { xs: "200px", md: "300px" },
    //         bottom: { xs: '-25px', md: '-50px' },
    //         left: { xs: "115px", md: "230px" },
    //         fontSize: { xs: '20px', md: '50px' },
    //       }}
    //     >
    //       <Typography sx={{ fontSize: { xs: '0.9rem', md: '1.2rem' } }}>
    //         Crafting stunning, functional websites.
    //       </Typography>
    //       <Typography sx={{ mt: 1, fontStyle: "italic", fontSize: { xs: '0.8rem', md: '1rem' } }}>
    //         -CFO, Industry
    //       </Typography>
    //     </WhiteBox>
    //   </Box>
    // </Box>
    <Box 
    id="H"
  sx={{
    minWidth: { xs: '100%', sm: '1000px' },
    // 100% width on mobile, 1000px on larger screens
  }}
>
<Box > 
  <img
    src="image/testimonial.jpg"
    alt="testimonial"
    style={{
      width: '100%', // Image will fill the Box width
      height: 'auto',
       // Maintains the aspect ratio
    }}
  />
  </Box>
</Box>

  );
}