import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MenuItem from './MenuItem'; // Ensure this is correctly imported

export default function MenuAppBar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const scrollToComponent = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error(`Element with id ${id} not found`);
    }
  };  
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
  const menuItems = [
    { label: 'Process', id: 'process' },
    { label: 'Benefits', id: 'benefits' },
    { label: 'Services', id: 'services' },
    { label: 'Pricing', id: 'pricing' },
    { label: 'FAQs', id: 'faqs' },
    { label: 'Contact Us', id: 'ContactUs' },
  ];

  return (
    <>
     <AppBar
  position="sticky"
  sx={{
    top: isMobile ? "30px" : '88%', 
    left: isMobile? "80px":"48%" ,
    transform: isMobile ? 'translateX(-50%)' : 'translateX(-50%)', 
    width: isMobile ? '80px' : '50%',
    float:{xs:"left"},
    borderRadius: isMobile ? "10px" : "65px",
    margin: "auto", 
  }}
>
  <Toolbar
    sx={{
      display: 'flex',
      justifyContent: isMobile ? 'space-between' : 'center', 
      height: '100px',
      padding: "0 16px",
      backgroundColor: isMobile?'white' : 'black',
      borderRadius: isMobile ? "10px" : "65px",
      fontSize: "20px",
    }}
  >
    {isMobile ? (
      <>
      <IconButton
  edge="start"
  aria-label="menu"
  onClick={toggleDrawer(true)}
  sx={{ fontSize: '3.4rem' ,marginRight:"15px",color:"black"}} 
>
  <MenuIcon sx={{ fontSize: 'inherit' }} /> 
</IconButton>

        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
        >
          <List>
            {menuItems.map((item) => (
             <ListItem 
             button 
             key={item.label} 
             onClick={() => {
               console.log(`Scrolling to ${item.id}`);
               scrollToComponent(item.id);
             }}
           >
             <ListItemText primary={item.label} />
           </ListItem>           
            ))}
          </List>
        </Drawer>
      </>
    ) : (
      menuItems.map((item) => (
        <MenuItem
          key={item.label}
          label={item.label}
          onClick={() => scrollToComponent(item.id)}
        />
      ))
    )}
  </Toolbar>
</AppBar>

    </>
  );
}
