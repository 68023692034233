import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

const ComparisonCard = () => {
  const [isInView, setIsInView] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
          } else {
            setIsInView(false);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  return (
    <Box 
    sx={{
      backgroundColor: '#0c0c0c',
      padding: '40px 0px',
      margin: '0',
      position: 'relative',
      borderRadius: '0px',
      alignItems: 'center',
    }}
  >
    {/* Header Section */}
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ marginTop: '40px', padding: '0 0px' }}
    >
      <Grid item sx={{ marginLeft: { xs: '70px', sm: '100px', md: '250px' } }} xs={12}>
        <Typography
          variant="h5"
          sx={{
            color: '#FF5733',
            fontWeight: 'bold',
            fontSize: { xs: '3rem', sm: '3rem', md: '4rem' },
            fontFamily: 'Syne, sans-serif',
          }}
        >
          No Contracts. No Headaches.
        </Typography>
        <Typography sx={{ color: 'white',margin:{xs:"5px 30px"}, fontSize: { xs: '1.9rem', sm: '2.5rem', md: '3rem' , fontFamily: 'Syne, sans-serif',} }}>
          Unlimited Services.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#FF5733',
            fontFamily: 'Syne, sans-serif',
            color: 'black',
            '&:hover': {
              backgroundColor: '#E64A19',
            },
            padding: { xs: '10px 22px', md: '10px 25px' },
            fontWeight: 'bold',
            borderRadius: '20px',
            textTransform: 'none',
            margin:{xs:"25px 40px" ,sm:'10px  170px',md:'10px 250px'},
            float:{xs:"right"},
            fontSize: { xs: '1.5rem', md: '2rem' },
          }}
        >
           <a href="https://calendly.com/thedesignerfactory-sales/15min" 
                    target="_blank" style={{textDecoration:"none",color:"black"}}>   Book an Intro Call  </a>
        </Button>
      </Grid>
    </Grid>

    {/* Inner White Container */}
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '20px',
        padding: { xs: '30px', md: '100px' },
        marginTop: '40px',
        marginLeft: { xs: '30px', md: '250px' },
        marginRight: { xs: '30px', md: '250px' },
        position: 'relative',
       
      }}
    >
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={6} sm={6} md={6} >
          <Typography
            variant="h6"
            
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '1.8rem', sm: '2.8rem', md: '4rem' },
              marginBottom: '20px',
              fontFamily: 'Syne, sans-serif',
            }}
          >
            TDF
          </Typography>
          <Box>
            {[
              'Fixed Predictable Cost',
              'Unlimited Revision',
              '48 Hrs Turnaround',
              'Dedicated Design & Team',
              'Access Full control',
            ].map((text, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingBottom: '10px',
                  marginBottom: '15px',
                  borderBottom: '1px solid #FFC107',
                  fontFamily: 'Syne, sans-serif',
                }}
              >
                <CheckIcon sx={{ color: 'green', marginRight: '10px', fontSize: '1.2rem' }} />
                <Typography
                  variant="body1"
                  sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' }, lineHeight: 1.5  , fontFamily: 'Syne, sans-serif', }}
                >
                  {text}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>

        {/* Right Column */}
        <Grid item xs={6} sm={6} md={6}  >
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '1.8rem', sm: '2.8rem', md: '4rem' },
              width:{xs:"auto",md:"auto"},
              marginBottom: '20px',
              fontFamily: 'Syne, sans-serif',
            }}
          >
            Other Design Agencies
          </Typography>
          <Box>
            {[
              'High Costs',
              'Limited Revisions',
              'Slow Turnaround',
              'One-Size-Fits-All',
              'Minimal Collaboration',
            ].map((text, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingBottom: '10px',
                  marginBottom: '15px',
                  borderBottom: '1px solid #FFC107',
                  fontFamily: 'Syne, sans-serif',
                }}
              >
                <CancelIcon sx={{ color: 'black', marginRight: '10px', fontSize: '1.2rem' }} />
                <Typography
                  variant="body1"
                  sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' }, lineHeight: 1.5 , fontFamily: 'Syne, sans-serif',}}
                >
                  {text}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>

      {/* Animated Image */}
      <Box
        ref={imageRef}
        component="img"
        src="image/vada.png"
        alt="Donut"
        sx={{
          position: 'absolute',
          bottom: '-100px', // Adjust the position as needed
          right: '-190px', // Adjust the position as needed
          width: { xs: '300px', sm: '500px', md: '800px' }, // Adjust size based on screen
          height: 'auto',
          zIndex: 10,
          transform: isInView ? 'scale(1)' : 'scale(0.5)',
          opacity: isInView ? 1 : 0,
          transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',
        }}
      />
    </Box>
  </Box>
  );
};

export default ComparisonCard;
