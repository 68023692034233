import React, { useRef, useState } from 'react';
import { Box, Button, Paper } from '@mui/material';

const VideoSection = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isButtonHovering, setIsButtonHovering] = useState(false);
  const [thumbnail, setThumbnail] = useState('video/thumbnail.jpg');
  let isDebouncing = false;

  const handlePlayClick = async () => {
    if (isDebouncing) return;
    isDebouncing = true;
  
    try {
      if (videoRef.current) {
        if (isPlaying) {
          videoRef.current.pause();
          setIsPlaying(false);
        } else {
          await videoRef.current.play();
          setIsPlaying(true);
        }
      }
    } catch (error) {
      console.error("Error attempting to play video:", error);
    } finally {
      setTimeout(() => {
        isDebouncing = false;
      }, 300);
    }
  };

  return (
    <Paper
    id="B"
      sx={{
        backgroundColor: '#FF5733',
        padding: { xs: '5px', sm: '10px' },
        height: { xs: '100vh', sm: '1200px' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopColor: 'white',
        borderBottomColor: 'white',
      }}
    >
      <Box
        sx={{
          width: { xs: '95%', sm: 'calc(100% - 10px)' },
          maxWidth: '3000px',
          height: { xs: '90%', sm: '1100px' },
          borderRadius: { xs: '25px', sm: '50px' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          margin: { xs: '10px', sm: '100px' },
        }}
      >
        <video
          ref={videoRef}
          width="100%"
          height="100%"
          style={{
            borderRadius: 'inherit',
            position: 'absolute',
            top: 0,
            left: 0,
            objectFit: 'cover',
          }}
          controls={false}
          poster={thumbnail || "https://via.placeholder.com/1200x800"}
        >
          <source
            src="video/video.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <Box
          onMouseEnter={() => setIsButtonHovering(true)}
          onMouseLeave={() => setIsButtonHovering(false)}
          sx={{
            position: 'relative',
            zIndex: 1,
            width: { xs: '120px', sm: '220px' },
            height: { xs: '120px', sm: '220px' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={handlePlayClick}
            sx={{
              backgroundColor: '#000',
              borderRadius: '50%',
              padding: { xs: '40px', sm: '70px' },
              minWidth: { xs: '60px', sm: '80px' },
              minHeight: { xs: '60px', sm: '80px' },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              opacity: isPlaying ? (isButtonHovering ? 1 : 0) : 1,
              transition: 'opacity 0.3s ease',
              '&:hover': {
                backgroundColor: '#000',
              },
            }}
          >
            <span style={{ 
              fontSize: { xs: '24px', sm: '40px' }, 
              color: '#FF5733' 
            }}>
              {isPlaying ? '❚❚' : '▶'}
            </span>
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default VideoSection;