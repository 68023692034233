import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

const CustomContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'black',
  padding: '55px',
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
    height: 'auto', // Adjust height for mobile
  },
}));

const CustomText = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '3rem',
  fontFamily: 'Syne, sans-serif',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  '& img': {
    width: '320px',
    height: '190px',
    [theme.breakpoints.down('sm')]: {
      width: '120px', // Smaller images on mobile
      height: '68px',
    },
  },
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  marginLeft: '100px',
  marginRight: '100px',
  lineHeight: '1.3',
  maxHeight: '10.5em',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '1.8rem',
  fontFamily: 'Syne, sans-serif',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
    marginLeft: '10px', // Adjusted margins for mobile
    marginRight: '10px',
    lineHeight: '1.4',
  },
}));

const items = [
  {
    title: 'Expert Team',
    description: `Our senior designers, strategists, and copywriters work closely with you to deliver top-notch quality.`,
    icon: <img src="image/stra.png" alt="Expert Team" />,
  },
  {
    title: 'Strategic Partnership',
    description: "We collaborate closely with you to craft a strategy that aligns with your brand's goals",
    icon: <img src="image/stra2.png" alt="Strategic Partnership" />
  },
  {
    title: 'Full-Service Creative',
    description: 'From branding and web development to performance creative and storytelling, we handle it all with meticulous attention to detail.',
    icon: <img src="image/stra3.png" alt="Full-Service Creative" />,
  },
  {
    title: 'Quick Turnaround',
    description: 'We ensure your projects are completed efficiently without compromising on quality.',
    icon: <img src="image/stra4.png" alt="Quick Turnaround" />,
  },
  {
    title: 'Tailored Solutions',
    description: "Every design is customized to reflect your brand's unique identity and message.   ",
    icon: <img src="image/stra5.png" alt="Tailored Solutions" />,
  },
  {
    title: 'Predictable Pricing',
    description: 'One fixed price for all your design needs, providing budget certainty and exceptional value.',
    icon: <img src="image/stra6.png" alt="Predictable Pricing" />,
  },
];

function ServicesSection() {
  return (
    <Box id="benefits">
      
      <CustomContainer>
      <Typography 
        
        align="left" 
        gutterBottom 
        sx={{ 
          marginLeft: { xs: '20px', sm: '250px' }, 
          padding:"25px",
          color: "#f08080",
          fontFamily: 'Syne, sans-serif',
          fontSize: { xs: '2rem', sm: '2.5rem',md:'5rem' }
        }}
      >
        Perks to Power Your Design Journey
      </Typography>
        <Box sx={{ 
          paddingLeft: { xs: '10px', sm: '110px' }, 
          paddingRight: { xs: '10px', sm: '110px' }
        }}>
          <Grid 
            container 
            spacing={{ xs: 2, sm: 4 }}
          >
            {items.map((item, index) => (
              <Grid item xs={6} sm={6} md={4} key={index}>
                <IconWrapper>{item.icon}</IconWrapper>
                <CustomText 
                  variant="h4" 
                  align="center" 
                  gutterBottom
                  sx={{
                    marginTop: { xs: '10px', sm: '15px' },
                    marginBottom: { xs: '10px', sm: '15px' }
                  }}
                >
                  {item.title}
                </CustomText>
                <DescriptionText align="center">
                  {item.description}
                </DescriptionText>
              </Grid>
            ))}
          </Grid>
        </Box>
      </CustomContainer>
    </Box>
  );
}

export default ServicesSection;