import { Box, Button, Typography } from '@mui/material';
import React from 'react';

const Footer = () => {
  const commonStyles = {
    fontFamily: "Syne, sans-serif",
  };

  const linkStyles = {
    ...commonStyles,
    color: "white",
    cursor: "pointer",
    fontSize: { xs: "20px", sm: "25px", md: "30px", lg: "35px" },
  };
 
  return (
    <Box id='ContactUs' sx={{ backgroundColor: "black", padding: 2 ,paddingBottom:"80px"}}>
      <Box 
        sx={{ 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundPosition: "center",
          margin: '20px'
        }}
      >
        <img 
          src="image/TDF.png" 
          alt="Logo" 
          style={{
            maxWidth: '100%',
            height: 'auto',
            width: { xs: '80%', sm: '60%', md: '50%' } // Responsive logo size
          }}
        />
      </Box>
      
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'auto',
          padding: 2,
          marginBottom: 2,
        }}
      >
        <Typography
          sx={{
            ...commonStyles,
            color: "white",
            margin: 'auto',
            fontSize: { xs: "16px", sm: "20px", md: "30px" }, 
            textAlign: 'center',
          }}
        >
          Where creativity meets convenience with unlimited design services,<br />
          tailored to scale your vision effortlessly.
        </Typography>
      </Box>

      <Box 
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'auto', 
          padding: 2,
          marginBottom: 2,
        }}
      >
        <Button 
          variant="contained" 
          sx={{
            backgroundColor: 'white',
            color: 'black',
            p: 1,
            fontSize: { xs: "20px", sm: "30px", md: "40px" },
            width: { xs: "80%", sm: "50%", md: "30%", lg: "18%" }, 
            fontWeight: "bold", 
            ...commonStyles,
            borderRadius: "10px",
            textTransform: "none"
          }}
        
        >
             <a href="https://calendly.com/thedesignerfactory-sales/15min" 
                    target="_blank" style={{textDecoration:"none"}}>  Get In Touch. </a>
         
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' }, 
          justifyContent: 'center',
          alignItems: 'center',
          padding: 5,
          gap: 4,
          margin:"18px"
        }}
      >
        <Typography sx={linkStyles}>Privacy</Typography>
        <Typography sx={linkStyles}>Terms & Conditions</Typography>
        <Typography sx={linkStyles}>Pricing</Typography>
        <Typography sx={linkStyles}>Contact Us</Typography> <br/>
       
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent:"center",
        paddingBottom:"30px"
       
      }}>
      <Typography sx={{
        color:"white",
         marginBottom:"35px",
         paddingBottom:"50px",
         fontFamily: "Syne, sans-serif",   
         fontSize: { xs: "20px", sm: "25px", md: "30px", lg: "33px" },
}}> Email : hello@thedesignerfactory.co
      </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
