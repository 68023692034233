import React from "react";
import { Box, Typography, Button, useTheme, useMediaQuery } from "@mui/material";

const HeroSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <Box
    id="process"
      sx={{
        backgroundImage: "url(image/banner.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: {
          xs: "50px 15px",
          sm: "100px 20px",
          md: "150px 20px",
        },
        textAlign: "center",
        color: "#fff",
        position: "relative",
        height: {
          xs: "100vh",
          sm: "900px",
          md: "1200px",
        },
        backgroundColor: "black",
      }}
    >
      {/* Centered TDF Text */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          top: {
            xs: "10px",
            sm: "15px",
            md: "20px",
          },
          width: "100%",
          height: {
            xs: "300px",
            sm: "500px",
            md: "700px",
          },
        }}
      >
        <Box 
          sx={{ 
            marginTop: {
              xs: "15px",
              sm: "20px",
              md: "30px"
            }
          }}
        >
          <img 
            src="image/TDF.png" 
            alt="Logo" 
            style={{
              maxWidth: isMobile ? '60%' : '100%',
              height: 'auto',
            }}
          />
        </Box>
      </Box>

      {/* Main Heading - Now in one line */}
      <Typography
        variant="h1"
        sx={{
          marginBottom: {
            xs: "20px",
            sm: "30px",
            md: "40px"
          },
          marginTop: {
            xs: "150px",
            sm: "200px",
            md: "250px"
          },
          fontSize: {
            xs: "2rem", // Smaller on mobile to fit one line
            sm: "3rem", // Adjusted for tablet
            md: "7rem"    // Original size for desktop
          },
          whiteSpace: "nowrap", // Prevents text from wrapping
          overflow: "hidden",    // In case of extremely small screens
          textOverflow: "ellipsis", // Shows ... if text gets cut off
        }}
      >
        <span style={{ 
          color: "white", 
          fontFamily: "Syne, sans-serif",
        }}>
          Transform Your
        </span>{" "}
        <span
          style={{
            color: "#FF5733",
            textDecoration: "underline",
            fontFamily: "Syne, sans-serif",
            fontWeight: 600,
          }}
        >
          Design Experience
        </span>
      </Typography>

      {/* Redefine Your Creative Partnership */}
      <Typography
        sx={{
          color: "white",
          fontSize: {
            xs: "2rem",
            sm: "3.5rem",
            md: "5rem"
          },
          marginTop: {
            xs: "30px",
            sm: "45px",
            md: "60px"
          },
          fontFamily: "Syne, sans-serif",
          fontWeight: 600,
        }}
      >
        Unlimited Designs. Fixed Cost. Infinite Control.
      </Typography>

      {/* Unlimited Designs text */}
      <Typography>
        <span
          style={{
            color: "#A9A9A9",
            fontSize: isMobile ? "1.5rem" : isTablet ? "2rem" : "3rem",
            fontFamily: "Syne, sans-serif",
            fontWeight: 600,
            display: 'block',
            margin: '0 auto',
            maxWidth: isMobile ? '300px' : 'none',
          }}
        >
          Redefine Your Creative Partnership
        </span>
      </Typography>

      {/* Button */}
      <Button
        sx={{
          backgroundColor: "#FF5733",
          color: "black",
          fontSize: {
            xs: "1.2rem",
            sm: "1.5rem",
            md: "2rem"
          },
          padding: {
            xs: "15px 20px",
            sm: "18px 25px",
            md: "20px 30px"
          },
          marginTop: {
            xs: "100px",
            sm: "150px",
            md: "200px"
          },
          borderRadius: "10px",
          marginBottom: "50px",
          fontFamily: "Syne, sans-serif",
          fontWeight: 600,
          textTransform: "none",
          whiteSpace: "nowrap",
          "&:hover": {
            backgroundColor: "#FF5733",
            color: "black",
          },
        }}
       
      >
        <a href="https://calendly.com/thedesignerfactory-sales/15min" 
        target="_blank"
         style={{textDecoration:"none",color:"black"}}> Start your Design Journey   </a>
   
</Button>
    </Box>
  );
};

export default HeroSection;