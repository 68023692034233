import React, { useEffect, useRef ,useState} from 'react';
import { Grid, Typography, Box, Paper, Accordion, AccordionSummary, AccordionDetails, IconButton, } from '@mui/material';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';

// Custom styled components
const CustomContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#FF5630',
  padding: '70px',
  borderTopColor: 'white',
  borderBottomColor: 'white',
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
  },
}));

const ServiceBox = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FF8666',
  borderRadius: '30px',
  fontFamily: 'Syne, sans-serif',
  marginLeft: '100px',
  boxShadow: 'none',
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0',
    height: '100%',
  },
}));

const CustomText = styled(Typography)(({ theme }) => ({
  color: 'black',
  fontFamily: 'Syne, sans-serif',
  [theme.breakpoints.down('sm')]: {
    '&.MuiTypography-h3': {
      fontSize: '1.2rem',
    },
    '&.MuiTypography-h4': {
      fontSize: '1rem',
    },
  },
}));

// Services array remains the same
// const services = [
//   {
//     title: 'Brand Creation',
//     description: 'Building unique, impactful brands that resonate.',
//   },
//   {
//     title: 'Web Design',
//     description: 'Crafting stunning, functional websites.',
//   },
//   {
//     title: 'Creative Strategy & Storytelling',
//     description: 'Developing compelling narratives for your brand.',
//   },
//   {
//     title: 'Performance Creative',
//     description: 'High-quality videos and motion graphics.',
//   },
//   {
//     title: 'Pitches & Presentations',
//     description: 'Creating persuasive, engaging presentations.',
//   },
// ];
const faqData = [
  { title: "Why wouldn't I just hire a full-time designer?", 
    description: "Good title! For starters, the annual cost of a full-time senior-level designer now exceeds $100,000, plus benefits (and good luck finding one available), Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize. With the monthly plan, you can pause and resume your subscription as often as you need to ensure you're only paying your designer when you have work available for them." },
    
  { title: "Is there a limit to how many requests I can have?",
    description: "Good title! For starters, the annual cost of a full-time senior-level designer now exceeds $100,000, plus benefits (and good luck finding one available), Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize. With the monthly plan, you can pause and resume your subscription as often as you need to ensure you're only paying your designer when you have work available for them."},
    
  { title: "How fast will I receive my designs?",
    description: "Good title! For starters, the annual cost of a full-time senior-level designer now exceeds $100,000, plus benefits (and good luck finding one available), Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize. With the monthly plan, you can pause and resume your subscription as often as you need to ensure you're only paying your designer when you have work available for them."},

  { title: "What will I recieve in 48 hours", 
    description: "Good title! For starters, the annual cost of a full-time senior-level designer now exceeds $100,000, plus benefits (and good luck finding one available), Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize. With the monthly plan, you can pause and resume your subscription as often as you need to ensure you're only paying your designer when you have work available for them."},

  { title: "Who are the designers?",
    description: "Good title! For starters, the annual cost of a full-time senior-level designer now exceeds $100,000, plus benefits (and good luck finding one available), Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize. With the monthly plan, you can pause and resume your subscription as often as you need to ensure you're only paying your designer when you have work available for them."},

  { title: "How does the pause feature work",
    description: "Good title! For starters, the annual cost of a full-time senior-level designer now exceeds $100,000, plus benefits (and good luck finding one available), Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize. With the monthly plan, you can pause and resume your subscription as often as you need to ensure you're only paying your designer when you have work available for them."},

  { title: "How do you handle larger requests?",
    description: "Good title! For starters, the annual cost of a full-time senior-level designer now exceeds $100,000, plus benefits (and good luck finding one available), Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize. With the monthly plan, you can pause and resume your subscription as often as you need to ensure you're only paying your designer when you have work available for them."},

  { title: "What programs do you design in?", 
    description: "Good title! For starters, the annual cost of a full-time senior-level designer now exceeds $100,000, plus benefits (and good luck finding one available), Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize. With the monthly plan, you can pause and resume your subscription as often as you need to ensure you're only paying your designer when you have work available for them."},

  { title: "How do I request designs",
    description: "Good title! For starters, the annual cost of a full-time senior-level designer now exceeds $100,000, plus benefits (and good luck finding one available), Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize. With the monthly plan, you can pause and resume your subscription as often as you need to ensure you're only paying your designer when you have work available for them." },

  { title: "What if I don't like the design?",
    description: "Good title! For starters, the annual cost of a full-time senior-level designer now exceeds $100,000, plus benefits (and good luck finding one available), Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize. With the monthly plan, you can pause and resume your subscription as often as you need to ensure you're only paying your designer when you have work available for them." },

  { title: "Is there any design work you don't cover?",
    description: "Good title! For starters, the annual cost of a full-time senior-level designer now exceeds $100,000, plus benefits (and good luck finding one available), Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize. With the monthly plan, you can pause and resume your subscription as often as you need to ensure you're only paying your designer when you have work available for them." },

  { title: "What if I only have a single request ?", 
    description: "Good title! For starters, the annual cost of a full-time senior-level designer now exceeds $100,000, plus benefits (and good luck finding one available), Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize. With the monthly plan, you can pause and resume your subscription as often as you need to ensure you're only paying your designer when you have work available for them."},

  { title: "Are there any refunds if I don't like the services",
   description: "Good title! For starters, the annual cost of a full-time senior-level designer now exceeds $100,000, plus benefits (and good luck finding one available), Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize. With the monthly plan, you can pause and resume your subscription as often as you need to ensure you're only paying your designer when you have work available for them."}

];
function FaqList() {
  const firstImageRef = useRef(null);
  const secondImageRef = useRef(null);
  const [expanded, setExpanded] = useState(-1); 

  const handleToggle = (index) => {
    setExpanded(prevExpanded => (prevExpanded === index ? -1 : index));
  };

  useEffect(() => {
    const handleScroll = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.transform = 'scale(1.2)';
          entry.target.style.opacity = 1;
        } else {
          entry.target.style.transform = 'scale(0.8)';
          entry.target.style.opacity = 0;
        }
      });
    };

    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.8,
    });

    const firstImage = firstImageRef.current;
    const secondImage = secondImageRef.current;
    
    if (firstImage) observer.observe(firstImage);
    if (secondImage) observer.observe(secondImage);

    return () => {
      if (firstImage) observer.unobserve(firstImage);
      if (secondImage) observer.unobserve(secondImage);
    };
  }, []);

  return (
    <CustomContainer id='faqs'>
  <img
    ref={firstImageRef}
    src="image/cir.png"
    alt="Decorative"
    style={{
      position: 'absolute',
      right: '30px',
      top: '50px',
      width: '20%',
      maxWidth: '350px',
      height: 'auto',
      transform: 'scale(0.8)',
      opacity: 0,
      transition: 'transform 1s ease-out, opacity 1s ease-out',
      zIndex: 2,
      // Add responsive sizing for mobile
      '@media (max-width: 600px)': {
        width: '60%', // Adjust size on mobile
        top: '20px',  // Adjust top position on mobile
        right: '20px', // Adjust right position
      },
    }}
  />

  <img
    ref={secondImageRef}
    src="image/15.png"
    alt="Decorative"
    style={{
      position: 'absolute',
      left: '50px',
      top: '700px',
      width: '20%',
      maxWidth: '120px',
      height: 'auto',
      transform: 'scale(0.8)',
      opacity: 0,
      transition: 'transform 1s ease-out, opacity 1s ease-out',
      zIndex: 2,
      // Add responsive sizing for mobile
      '@media (max-width: 600px)': {
        width: '20%', // Adjust size on mobile
        left: '10px',  // Adjust left position on mobile
        top: '400px',  // Adjust top position
      },
    }}
  />

  <Typography
    variant="h2"
    align="left"
    gutterBottom
    sx={{
      marginLeft: { xs: '20px', sm: '50px' ,md:"10px"},
      color: 'white',
      fontFamily: 'Syne, sans-serif',
      fontSize: { xs: '2.rem', sm: '3.75rem' },
      marginTop: { xs: '20px', sm: '30px' }, // Added margin for mobile
      p:3
    }}
  >
    Frequently Asked Questions
  </Typography>

  {/* <Grid container spacing={{ xs: 2, sm: 10 }} sx={{ padding: { xs: '10px', sm: '70px' } }}> */}
    {/* {services.map((service, index) => (
      <Grid
        item
        xs={6} // This makes it 2 columns on mobile
        sm={6} // Maintains 2 columns on tablet
        md={6} // Maintains original layout on desktop
        key={index}
      >
        <ServiceBox
          sx={{
            padding: { xs: '15px', sm: '50px' },
            minHeight: { xs: '120px', sm: 'auto' },
          }}
        >
          <CustomText variant="h3" gutterBottom>
            {service.title}
          </CustomText>
          <CustomText variant="h4">
            {service.description}
          </CustomText>
        </ServiceBox>
        {index === 3 && (
          <Box
            mt={1}
            sx={{
              position: 'absolute',
              marginTop: { xs: '50px', sm: '150px' },
              marginLeft: { xs: '10px', sm: '120px' },
              color: 'white',
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '1.2rem', sm: '2.5rem' },
              }}
            >
              And many more.............
            </Typography>
          </Box>
        )}
      </Grid>
    ))} */}

{faqData.map((item, index) => (
      <Accordion
      key={index}
      expanded={expanded === index}
      onChange={() => handleToggle(index)}
      sx={{
        backgroundColor: '#FCA392',
        padding: "10px",
        margin: "28px",
        borderRadius: '15px',
        border: '2px solid none',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <AccordionSummary
        expandIcon={
          <IconButton
            sx={{
              backgroundColor: 'white',
              borderRadius: '50%',
              marginRight:"20px",
              width: '70px',
              height: '70px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
          >
            {expanded === index ? (
              <Remove sx={{ color: '#f35444', fontSize: 48 }} />
            ) : (
              <AddIcon sx={{ color: '#f35444', fontSize: 48 }} />
            )}
          </IconButton>
        }
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Typography variant="h4" sx={{ p: 2, pb: 0, m: 2, fontFamily: 'Syne, sans-serif',fontSize:{xs:'28px',sm:"34px" ,md:'35px'} }}>
          {item.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{ p:2,m:2, fontFamily: 'Syne, sans-serif', fontSize: "27px" }}>
          {item.description}
        </Typography>
      </AccordionDetails>
    </Accordion>
      ))}
  {/* </Grid> */}
</CustomContainer>

  );
}

export default FaqList;