import React, { useEffect, useState, useRef } from 'react';
import { Grid, Typography, Box, Paper } from '@mui/material';
import { padding, styled} from '@mui/system';

const CustomContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#FF5630',
  padding: '50px',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: '20px',

  },

}));

const ServiceBox = styled(Paper)(({ theme }) => ({
  padding: '30px',
  marginLeft: '110px',
  marginRight: '30px',
  backgroundColor: '#FF8666',
  borderRadius: '30px',
  boxShadow: 'none',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    width:"330px",
    height: "360px",
    padding: '10px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  [theme.breakpoints.down('md')]:{
    height: "500px",                    
   
  }
}));

const TitleText = styled(Typography)(({ theme }) => ({
  color: 'black',
  fontFamily: 'Syne, sans-serif',
  fontSize: '2.5rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    padding:"10px 5px"
  },
  [theme.breakpoints.down('md')]:{
    fontSize: '2.1rem',
  },
  [theme.breakpoints.up('md')]:{
    fontSize: '2.5rem',
  }
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
  color: 'black',
  padding:"20px",
  fontFamily: 'Syne, sans-serif',
  fontSize: '1.4rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.6rem',
    padding:"0px 5px"
  },
  [theme.breakpoints.down('md')]:{
    fontSize: '1.4rem',
    padding:"2px"
  },
 
}));


const AnimatedImage = styled('img')(({ isVisible, theme }) => ({
  width: "300px",
  transition: 'transform 1s ease-out, opacity 1s ease-out',
  transform: isVisible ? 'scale(1.2)' : 'scale(0.8)',
  opacity: isVisible ? 1 : 0,
  [theme.breakpoints.down('sm')]: {
    width: '60px',
    height: 'auto',
  },
}));

const services = [
  {
    title: ' Website & Landing Page Design',
    subTitle: 'Landing Pages | Webflow | Blog Graphics',
    description: 'Create responsive and high-performing websites, landing pages, and visuals for blogs'
  },
  {
    title: 'Mobile & App Design',
    subTitle: 'Mobile Apps | UI/UX Design | Prototyping',
    description: 'Design seamless user experiences for mobile apps with interactive prototypes'
  },
  {
    title: ' Brand Identity & Guidelines',
    subTitle: 'Branding | Logo | Brand Guides',
    description: 'Develop distinctive brand identities with consistent visual guidelines'
  },
  {
    title: 'Presentation & Deck Design',
    subTitle: 'Slide Decks | Infographics | Pitch Decks',
    description: 'Create impactful presentations and data-driven visuals for business growth'
  },
  {
    title: 'Social Media & Content Design',
    subTitle: 'Social Media | Ad Creatives | Banners',
    description: 'Deliver eye-catching visuals and campaigns to engage audiences across platforms'
  },
  {
    title: 'Comprehensive Visual Solutions',
    subTitle: ' Logo | Landing Pages | Webflows',
    description: 'Offer complete design solutions for businesses, from branding to digital presence'
  },
];

function ServicesList() {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.5,
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
      setIsVisible(false);
    };
  }, []);

  return (
    <CustomContainer ref={containerRef} id="services">
      <Typography
        variant="h2"
        align="left"
        gutterBottom
        sx={{
          marginTop: '20px',
          marginLeft: { xs: '70px', sm: '250px', md: "145px" },
          color: 'white',
          fontFamily: 'Syne, sans-serif',
          paddingLeft: {xs:"79px",sm:"40px"} ,
          fontSize: { xs: '4rem', sm: '5.5rem', md: '4.5rem' }
        }}
      >
        What all we offer
      </Typography>
      <Grid
        container
        spacing={{ xs: 2, md: 4 }}
        sx={{
          padding: { xs: '20px', sm: '50px' },
          borderRadius: '20px'
        }}
      >
        {services.map((service, index) => (
          <Grid
            item
            xs={6}
            md={6}
            key={index}
            sx={{
              position: 'relative',
              padding: { xs: '10px', sm: '30px' }
            }}
          >
            <ServiceBox>
              <TitleText gutterBottom sx={{p:2}} >                    
                {service.title}
              </TitleText>
              <DescriptionText sx={{mb:3,fontSize: "33px" }}>
                {service.subTitle}
              </DescriptionText>
              <DescriptionText
               sx={{
                paddingTop:{xs:"50px",sm:"30px",md:"10px"},
                fontStyle: "italic",
               }}>
                {service.description}
              </DescriptionText>
              {index === 0 && (
                <AnimatedImage
                  src="image/vij.png"
                  alt="Decorative Element 1"
                  sx={{
                    position: 'absolute',
                    left: { xs: '-50px', sm: '-110px' },
                    top: { xs: '220px', sm: '310px',md:"220px" },
                    zIndex: 1,
                    width: { xs: '80px', sm: '140px',md:"200px" },
                    height: 'auto',
                  }}
                  isVisible={isVisible}
                />
              )}

              {index === services.length - 1 && (
                <AnimatedImage
                  src="image/Pipe.png"
                  alt="Decorative Element 2"
                  isVisible={isVisible}
                  sx={{
                    position: 'absolute',
                    right: { xs: '-40px', sm: '-180px' },
                    bottom: { xs: '-40px', sm: '-160px',md:'-110px' },
                    zIndex: 1,
                    width: { xs: '140px', sm: '350px',md:'340px' },
                    height: { xs: '120px', sm: '210px',md:'250px' },
                  }}
                />
              )}
            </ServiceBox>
          </Grid>
        ))}
      </Grid>
    </CustomContainer>
  );
}

export default ServicesList;