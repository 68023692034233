import React, { useEffect, useRef } from "react";
import { TextField, Button, Box, Typography, styled } from "@mui/material";

// Define styled components with animation
const AnimatedImage = styled("img")(({ theme }) => ({
  width: "400px",
  height: "auto",
  position: "absolute",
  transform: "translateX(-100%)", // Start off-screen to the left
  opacity: 0,
  transition: "transform 1s ease-out, opacity 1s ease-out", // Smooth transition
}));

const ContactForm = () => {
  const leftImageRef = useRef(null);
  const rightImageRef = useRef(null);

  useEffect(() => {
    const handleScroll = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.transform = "translateX(0)"; // Move to original position
          entry.target.style.opacity = 1; // Fade in
        } else {
          // Reset to initial state when not in view
          if (entry.target === leftImageRef.current) {
            entry.target.style.transform = "translateX(-100%)";
          } else if (entry.target === rightImageRef.current) {
            entry.target.style.transform = "translateX(100%)";
          }
          entry.target.style.opacity = 0;
        }
      });
    };

    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.1, // Trigger animation when 10% of the element is in view
    });

    if (leftImageRef.current) observer.observe(leftImageRef.current);
    if (rightImageRef.current) observer.observe(rightImageRef.current);

    return () => {
      if (leftImageRef.current) observer.unobserve(leftImageRef.current);
      if (rightImageRef.current) observer.unobserve(rightImageRef.current);
    };
  }, []);

  return (
    <Box
    id="J"
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Stack vertically on small screens
        height: "auto", // Change to auto for responsiveness
        backgroundColor: "#0c0c0c",
        padding: "20px",

        position: "relative",
        gap: { xs: "50px", md: "300px" }, // Adjust gap for smaller screens
      }}
    >
      {/* Left Image */}
      {/* <AnimatedImage
    src="image/Pipe.png"
    alt="Left Side Image"
    ref={leftImageRef}
    sx={{
      position: 'absolute', // Make sure this is positioned correctly
      left: '100px',
      top: 'calc(50% - 150px)', // Adjust as needed
      zIndex: 1, // Ensure it's above the form but not overlapping
    }}
  /> */}

      {/* Right Image */}
      <AnimatedImage
        src="image/cub.png"
        alt="Right Side Image"
        ref={rightImageRef}
        sx={{
          position: "absolute", // Use absolute positioning for better control
          right: "100px", // Adjust as needed
          top: "calc(10%)", // Position the image higher in the layout
          transform: "translateX(100%)", // Start off-screen to the right
          width: { xs: "40%", md: "auto" }, // Responsive width for image
          zIndex: 1, // Ensure it's above the form but not overlapping
        }}
      />

      {/* Left Side - Get in Touch */}
      <Box
        sx={{
          color: "white",
          flex: 1,
          textAlign: "left",
          marginLeft: { xs: "20px", md: "300px" }, // Adjust margin for smaller screens
          marginTop: "100px",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "50px", md: "100px" }, // Responsive font size
            whiteSpace: "nowrap",
          }}
        >
          <span style={{ color: "grey", fontFamily: "Syne, sans-serif" }}>
            Get
          </span>{" "}
          in Touch.
        </Typography>
        <Typography
          sx={{
            color: "#bbbbbb",
            fontSize: { xs: "20px", md: "30px" }, // Responsive font size
            fontFamily: "Syne, sans-serif",
          }}
        >
          So that we can talk more about...
        </Typography>
      </Box>

      {/* Right Side - Form Card */}
      <Box
        sx={{
          backgroundColor: "white",
          padding: "50px",
          borderRadius: "15px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          minWidth: { xs: "90%", md: "700px" }, // Responsive min width
          height: "auto", // Change height to auto for responsiveness
          marginRight: { xs: "10px", md: "1000px" }, // Adjust margin for smaller screens
          marginTop: "100px",
          zIndex: 0, // Ensure the form is below the animated image
          position: "relative", // Ensure proper stacking context
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#2f2f2f",
            fontWeight: "bold",
            marginBottom: "20px",
            fontSize: { xs: "30px", md: "40px" }, // Responsive font size
            fontFamily: "Syne, sans-serif",
          }}
        >
          Name{" "}
          <Typography
            component="span"
            sx={{
              color: "#ff5a5f",
              fontSize: "30px",
              fontFamily: "Syne, sans-serif",
            }}
          >
            (Required)
          </Typography>
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            flexDirection: { xs: "column", md: "row" },
            marginBottom: "20px",
          }}
        >
          {/* First Name Section */}
          <Box sx={{ flex: 1 }}>
            <Typography>First</Typography>
            <TextField
              placeholder="First"
              required
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "#e9e9e9",
                },
              }}
            />
          </Box>

          {/* Last Name Section */}
          <Box sx={{ flex: 1, marginBottom: "20px" }}>
            <Typography>Last</Typography>
            <TextField
              placeholder="Last"
              required
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "#e9e9e9",
                },
              }}
            />
          </Box>
        </Box>

        <Typography
          variant="h6"
          sx={{
            color: "#2f2f2f",
            fontWeight: "bold",
            marginBottom: "10px",
            fontFamily: "Syne, sans-serif",

            fontSize: { xs: "30px", md: "40px" }, // Responsive font size
          }}
        >
          Email{" "}
          <Typography
            component="span"
            sx={{
              color: "#ff5a5f",
              fontSize: "30px",
              fontFamily: "Syne, sans-serif",
            }}
          >
            (Required)
          </Typography>
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            flexDirection: { xs: "column", md: "row" },
            marginBottom: "20px",
          }}
        >
          {/* First Name Section */}
          <Box sx={{ flex: 1 }}>
            <Typography>Enter Email</Typography>
            <TextField
              placeholder="Enter Email"
              required
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "#e9e9e9",
                },
              }}
            />
          </Box>

          {/* Last Name Section */}
          <Box sx={{ flex: 1 }}>
            <Typography>confirm Email</Typography>
            <TextField
              placeholder="confirm Email"
              required
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "#e9e9e9",
                },
              }}
            />
          </Box>
        </Box>

        <Typography
          variant="h4"
          sx={{
            color: "#2f2f2f",
            fontWeight: "bold",
            marginBottom: "10px",
            fontFamily: "Syne, sans-serif",
          }}
        >
          Comments/Questions{" "}
          <Typography
            component="span"
            sx={{ color: "#ff5a5f", fontSize: "30px" }}
          >
            (Required)
          </Typography>
        </Typography>
        <TextField
          placeholder="Shoot me a few details about your project."
          multiline
          rows={4}
          fullWidth
          InputLabelProps={{
            style: { color: "#ff5a5f" },
          }}
          sx={{
            backgroundColor: "#e9e9e9",
            marginBottom: "20px",
          }}
        />
        <Typography
          sx={{
            color: "#6f6f6f",
            fontSize: "30px",
            marginBottom: "20px",
            fontFamily: "Syne, sans-serif",
          }}
        >
          0 of 600 max characters
        </Typography>
        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#000000",
            color: "white",
            "&:hover": {
              backgroundColor: "#333333",
            },
            fontWeight: "bold",
            borderRadius: "5px",
            fontSize: "30px",
            fontFamily: "Syne, sans-serif",
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default ContactForm;
