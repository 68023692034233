import React, { useState, useRef } from 'react';
import HeroSection from './components/HeroSection';
import VideoSection from './components/VideoSection';
import Portfolio from './components/Portfolio';
import CustomSection from './components/cusomdection';
import ServicesSection from './components/ServicesSection';
import ServicesList from './components/boxList';
import ComparisonCard from './components/ComparisonCard';
import CostCalculator from './components/CostCalculator';
import ContactForm from './components/ContactForm';
import './App.css';
import Testimonials from './components/textmonial';
import FaqList from './components/FAQ';
import MenuAppBar from './components/MenuAppBar';
import Footer from './components/Footer';


function App() {
    
    return (
      
      <div>
        <MenuAppBar/>
        <HeroSection /> 
        <VideoSection />
        <Portfolio />
        <CustomSection />
        <ServicesSection /> 
        <ServicesList />
         <ComparisonCard />
         <Testimonials/> 
        <CostCalculator />
        <FaqList />
        {/* <ContactForm /> */}
        <Footer/>
      
      </div>
    
  );
}

export default App;
