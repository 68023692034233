import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';

const CustomSection = () => {
  const starRef = useRef(null);
  const leftImageRef = useRef(null);

  useEffect(() => {
    const handleScroll = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.transform = 'scale(1.2)';
          entry.target.style.opacity = 1;
        } else {
          entry.target.style.transform = 'scale(0.8)';
          entry.target.style.opacity = 0;
        }
      });
    };

    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.1,
    });

    const star = starRef.current;
    const leftImage = leftImageRef.current;

    observer.observe(star);
    observer.observe(leftImage);

    return () => {
      observer.unobserve(star);
      observer.unobserve(leftImage);
    };
  }, []);

  return (
    <Box
    id="D"
      sx={{
        backgroundColor: '#ff5b3a',
        padding: { xs: '20px', md: '40px 20px' }, // Adjust padding for smaller screens
        height: '1000px',
        textAlign: 'center',
        color: '#ffffff',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: '400',
          marginBottom: '20px',
          color: 'black',
          fontSize: { xs: '2rem', md: '5rem' }, // Responsive font size
          marginTop: '30px',
          fontFamily: 'Syne, sans-serif',
        }}
      >
        We are {' '}
        <span style={{ color: 'white' , fontFamily: 'Syne, sans-serif', }}>
         Not your Typical Design Agency.
        </span>{' '}
        <br />
        We Do Things differently. We prioritize Your brand's <br />
        success with an approach that offers.
      </Typography>
      <Typography
        sx={{
          fontWeight: '700',
          marginTop: '150px',
          fontSize: { xs: '2.5rem', md: '5rem' }, // Responsive font size
          fontFamily: 'Syne, sans-serif',
          fontWeight: 'bold', // Syne SemiBold
        }}
      >
        Endless Creativity <br />
        Predictable pricing
      </Typography>

      {/* Right side image */}
      <img
        ref={starRef}
        src="image/S.png"
        alt="Star"
        style={{
          position: 'absolute',
          bottom: '90px',
          right: '100px',
          width: '400px',
          height: '400px',
          transform: 'scale(0.8)',
          opacity: 0,
          transition: 'transform 1s ease-out, opacity 1s ease-out',
          maxWidth: '100%', // Ensure images scale down on smaller screens
        }}
      />

      {/* Left side image */}
      <img
        ref={leftImageRef}
        src="image/round.png"
        style={{
          position: 'absolute',
          bottom: '-120px',
          left: '-100px',
          width: '400px',
          height: '400px',
          transform: 'scale(0.8)',
          opacity: 0,
          transition: 'transform 1s ease-out, opacity 1s ease-out',
          maxWidth: '100%', // Ensure images scale down on smaller screens
        }}
      />
    </Box>
  );
};

export default CustomSection;
