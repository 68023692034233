import React from 'react';
import Typography from '@mui/material/Typography';

export default function MenuItem({ label, onClick }) {
  return (
    <Typography
      variant="h4"
      sx={{
        textAlign:"center",
        height:"62px",
        cursor: 'pointer',
        margin:"10px",
        flexGrow: 2,
        padding:'12px 0px',
        fontWeight:'bold',
        borderRadius: '48px',
        transition: 'background-color 0.3s, color 0.3s',
        '&:hover': {
          backgroundColor: 'yellow',
          color: 'black',
        },
      }}
      onClick={onClick}
    >
      {label}
    </Typography>
  );
}
